// const dev = {
//   API_ENDPOINT_URL: 'http://localhost:3002/api/v1'
// };

// const prod = {
//   API_ENDPOINT_URL: 'https://tcblapi.poindexters.ca/api/v1'
// };

// const test = {
//   API_ENDPOINT_URL: 'https://tcblapi.poindexters.ca/api/v1'
// };

const getEnv = () => {
	
	// switch (process.env.NODE_ENV) {
	// 	case 'development':
	// 		return dev
	// 	case 'production':
	// 		return prod
	// 	case 'test':
	// 		return test
	// 	default:
	// 		break;
	// }
	return {
		API_ENDPOINT_URL: process.env.REACT_APP_DEV_API_URL
	  };
}

export const env = getEnv()
